// import { AmplifyS3Image } from '@aws-amplify/ui-react'
import { useTheme } from '@aws-amplify/ui-react'
import EventIcon from '@mui/icons-material/Event'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import RouteIcon from '@mui/icons-material/Route'
import { Link } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Storage } from 'aws-amplify'
import { uniq } from 'lodash'
import React, { useEffect, useState } from 'react'

import HashLink from './elements/HashLink'
import ImageCarousel, { CarouselItemProps } from './elements/ImageCarousel'
import TextWithIcon from './elements/TextWithIcon'
import { Event as EventApi } from '../API'
import { formatDate } from '../constants/formats'

type Event = Omit<Exclude<EventApi, null>, '__typename'>

interface Props {
  event: Event
  handleLocationClick?: (value: boolean) => void
}
const EventDetailsInfo: React.FC<Props> = ({ event, handleLocationClick }) => {
  const { tokens } = useTheme()
  const [presignedUrl, setPresignedUrl] = useState<CarouselItemProps[]>([])

  useEffect(() => {
    const fetch = async () => {
      const presignedUrls: string[] = []
      if (event?.fullCover && event.fullCover.length > 0) {
        for await (const fc of event.fullCover) {
          const presigned = await Storage.get(fc.key)
          presignedUrls.push(presigned)
        }
      } else if (event?.cover?.key) {
        const presigned = await Storage.get(event?.cover?.key)
        presignedUrls.push(presigned)
      }
      const uniqueUrls = uniq(presignedUrls)
      if (uniqueUrls.length > 0)
        setPresignedUrl(
          presignedUrls.map((url) => ({
            url,
          })),
        )
    }
    if (event?.cover?.key || event?.fullCover?.length > 0) fetch()
    else
      setPresignedUrl([
        { url: `${process.env.PUBLIC_URL}/images/megatechph_icon.png` },
      ])
  }, [event])

  return (
    <Grid container spacing={8}>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Typography variant="h4">{`${event.isVirtual ? 'VIRTUAL - ' : ''}${
          event.name
        }`}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        container
        spacing={4}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid
          item
          xs={12}
          container
          direction="column"
          justifyContent="space-around"
          alignItems="stretch"
          spacing={4}>
          <Grid item container xs={12}>
            <ImageCarousel
              items={presignedUrl}
              imageStyle={event.fullCoverStyle}
              navButtonsAlwaysVisible
              autoPlay={false}
              animation="fade"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          style={{
            padding: tokens.space.medium.value,
          }}>
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item xs={12}>
              <TextWithIcon
                Icon={EventIcon}
                title="Event Dates"
                text={
                  event?.startDateTime
                    ? `${formatDate(event.startDateTime)}`
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextWithIcon
                Icon={LocationOnIcon}
                title="Location"
                text={event.location || ''}
                id="location"
              />
            </Grid>
            {event.locationImage && (
              <Grid item xs={12}>
                <TextWithIcon
                  Icon={RouteIcon}
                  title="Event Route"
                  text={
                    <Link
                      href="#"
                      sx={{ textDecoration: 'none' }}
                      onClick={() => handleLocationClick(true)}>
                      Click here
                    </Link>
                  }
                  id="location"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextWithIcon
                Icon={EventBusyIcon}
                title="Registration"
                text={`${
                  event.registrationStartDateTime &&
                  formatDate(event.registrationStartDateTime)
                }${event.registrationStartDateTime && ' to '}${
                  event.registrationCutOff
                    ? formatDate(event.registrationCutOff)
                    : ''
                }`}
              />
            </Grid>
            {event.raceKitCollectionLocation && (
              <Grid item xs={12}>
                <TextWithIcon
                  Icon={LocationCityIcon}
                  title="Race Kit Collection"
                  text={event.raceKitCollectionLocation || 'TBC'}
                  id="location"
                />
              </Grid>
            )}
            {event.collectionLocation && (
              <Grid item xs={12}>
                <TextWithIcon
                  Icon={LocationCityIcon}
                  title="Collaterals Claim Site"
                  text={event.collectionLocation || 'TBC'}
                  id="location"
                />
              </Grid>
            )}
            {!!event.categories?.items?.length && (
              <Grid
                item
                container
                xs={12}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <HashLink smooth to={'#categories'}>
                  <Button variant="contained" color="secondary">
                    View Categories
                  </Button>
                </HashLink>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {!!event.overview?.length && (
        <Grid item container xs={12}>
          <div
            dangerouslySetInnerHTML={{ __html: event.overview }}
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}></div>
        </Grid>
      )}
    </Grid>
  )
}

export default EventDetailsInfo
