import { compareHash } from '../utils/ObjectUtil'

const prefix = `$2a$10$DstN3bRFHGc0uIz51KpOme`

const pastParticipants = {
  'twm-camotes-2024': {
    '404': 'VcE17wXA9f0EhqUliKlCpVOuMjPo4iC',
    '101': 'cEtg86JHxOv4xm17RXsaMu7xblWsxou',
    '102': 'chYxdyxtihnWVPgUDEYOwl5okaigb.q',
    '103': '4zOAa.N/qx1M/9hJg5fQAMrdkf.M4vG',
    '105': 'FJD6t9lf.Cib2uncsoUF4kx5kgwCs.G',
    '106': 'FeJPK8VNm5HBOLOvIeK2NNGZcHjXSNG',
    '107': 'Of0eL/2j6VY5E0wdJ5ZJsZUgkdf5JE.',
    '108': 'tsY7Lyj5Kpba3TpGw4.mPJALdkIeyle',
    '110': 'iSB2prjMfH/DfQqtxqrngyiac4jMjxG',
    '111': 'Mf.Z5Yig2XQ5CP2pJKIC68/PD0t.zzq',
    '112': 'vtDimfErJ3yZx0fbcJS/QaWao98Yike',
    '113': 'C5N/zhD6pYWZv90/7ZmKCQdPC9jcLv2',
    '114': '3tMeTb6g/6YKTUJ7tzavQIkA0tRx4L.',
    '115': 'ecRHsSX/1dnpdkIQakw6i8QVGlRxg6C',
    '116': 'Dy8VtAWb6fIoa431YzYRqQcf4gjo1B6',
    '117': 'AeCDRPsPCEiJk1CTaoJ1vks2ZavrbKa',
    '118': 'aHQ7ij6uBZzRt6jFVqGr.OVSELDMYka',
    '119': 'AX5BloiGg.bjllrAVWJ2L2QcZNRJo3S',
    '120': 'KPqqrotB6zWDMHGrP1/PjgL..LUxRLG',
    '121': 'hmtNhETttCeuTQEuZtdcI9kEvJ9Nxme',
    '123': 'l6E5PQJ1GeIVe5uv9rFC6C5qiP48qUy',
    '124': 'OOSzMe5PjKiBlYggiMn1z6wDRt4fbLS',
    '125': 'TggeOv2LOTLQ4Fa4hb2L.RmbMjlghrm',
    '126': '5VXup0/Tl.l77Jvpn6zjfkjVNO1A0ZC',
    '127': '.1CMbwBka9Ql6r5wdG34peq9cz37Zae',
    '128': 'Snj5hWgmJdNiB26L/Hcv84eJzLG6K.W',
    '129': '2bcxaTZjtU06jL1Koex63leexY0hMDO',
    '130': 'rCFAZWoBqbC.pi6Xq/Ap4D24vUeqgpy',
    '131': 'BZYYyOAOMbCrlp7nnYpj4C7JKTFyBFK',
    '132': 'ExSg0wORzLKdYS0qt6g00xiwJOIzif.',
    '133': '1DHG.EmjZQbez0KStLuUi0UhFTaJvlq',
    '135': '0F9wp8rEJ8AJR.UyOzVRpVKjqDsNM7e',
    '136': 'FfkZu8klixPxAvExSdDtxYGEbmQB.RK',
    '137': 'MwYlX4FClEoHyQQgJi7XnCfBbplDpYq',
    '138': 'WXSFMC6F9sQkvZbqNt2L3sUviNCwfd.',
    '139': 'fhnxVpZwbQSdkpQuJfP/Qm5GguypKdm',
    '140': 'TN.peB0c.RM8o0IWRdGfGYAfODWYxly',
    '141': 'IARHi1KNLuIVS8OKADAa.wBSBOHWi6u',
    '142': 'YPEzVfg.cpusYfmY45lGjwllrjaqgE.',
    '143': 'dF4wDcMPjTiCRSxqkfray0fl7dNN5F.',
    '144': '.BB8basZIrTzj/4yB2oh/0xiPF.LtJG',
    '145': '5OLOa0dXQK8BJ/la.5e.4WwdN0qzlhO',
    '146': '6LPMRowqLcIdah6nx.sqb/aDwA73/W.',
    '148': 'wxnkdAh6DagmSmtf/BnP5wvtLWb0yvm',
    '149': 'ujLMJycrsHIYHSz5YzFczV796JjmWOi',
    '150': 'gYlmXpbKQOzLlbRJTS7wnZv8Pfg/uDa',
    '151': 'XFE213q8EH/VeOzl/YW3o/u5Q9kkuEq',
    '152': 'vcqdx5aYPfplGqK8XnLoF9f1g1qSkbq',
    '153': 'RAb/S1/a8K8k1DQcwGATR4buFDCxhKG',
    '155': '2FDOKMO46cAwa2xX0v6wI3Ep1e7we92',
    '156': 'FOcjmbKxeE0toazaX1gliV3OZlz8kNK',
    '157': 'Y3VI6syCq/DRNs0yBySygadZGwyRCD2',
    '158': 'tNcYvMSzbeMsgRzNQlh2b/gBxpxTgG.',
    '159': 'MftE/otrTuO.KSewWQ8ozCVAEZbSlES',
    '160': 'ZjDcQk3/AjMJB4bb4aZ8kW/TfS35JQy',
    '161': 'V0oi3uVCjGaQM5egU1wcM7JMIBoVRnC',
    '162': 'hIaDKmR/xx6EVy3EgVsGTHARzOraRq2',
    '163': 'RZb1fk30ZeqMdKpJdbC.n6KXy2GhpJm',
    '164': 'bQLJbICE9Ew8UfP1fqiifl5pPFY.ggm',
    '165': 'ngTHG61VZZr91ZTbyVvskksXGImmhhy',
    '166': 'XV/Us2Y.IrPDpJHJY27vdPl7uynzWhS',
    '167': '5QardKAacXsodk.Re9MkmUs5E9wO5YS',
    '168': 'QNPce5tWJJ5nVUNQ3wbZDxSen2.Wc5G',
    '169': 'xd4u.uhRQzzHIg2sRpKyby.eduiDkhq',
    '170': 'AkNx2Ak2qbT1MAsTRPpHnTvBK6B52yG',
    '171': 'Cl9ur/vhVEUTR9q6hjnG26Z/brGrbRm',
    '172': '78Whd1.j5rfwbpTwl48DPgzI927Rgqu',
    '173': '8YFzoRbTvtO7Lp6ZRGx1EmS/VRDRtKm',
    '174': 'j0Jkm5p16p5Zh/Dx/NhIpY8qFev0g56',
    '175': 'jw3ZUjupQ368f4JdpqUlt28ay62KWo6',
    '176': 'go3IlSPoqFcuaeeqvaS0jIqSIXedAPO',
    '177': 'dvvZhY.bll/lXpF2EdctLOd9qx2s7Eq',
    '178': 'IPyFyLlYAGUAWsliT4Edrugiu1L218i',
    '179': 'lIEqywYe0dmEJn22GT9XD5ZZjWZ6SsG',
    '180': '/ayccZAGFBYNkJmN3q2skLpeAk1ekyO',
    '181': 'uuS1rM8BxyzZidhNv11RPVkDbD63NfG',
    '182': 'uy1gOFCx8I5TjjeodzD6WnYjyKOHkfG',
    '184': 'iTLH.6pTlwaA8C1oNnYE6e/2l.sYtfi',
    '185': '9WX9KWH6AwT1VtRERo1HF8pzYUI6slu',
    '186': 'puYkYwHWldmRYh.ZR9PwpY4FaYmCk9G',
    '187': 'kiEwTedDytg29i.pv0vqpmM3OejH85q',
    '188': '1coJ7U7sM2r6ASgGdcFIA6aBihVtA56',
    '189': 'DQVAk7qOQ45CpDU/pBdSnHCFeVpfyYm',
    '190': 'efEHH.WIQNSoj9u9wAR4YbQJ8Lidd7m',
    '191': 'PA25xM4PLO2Qc7gMvKp6LQm.wyAgLry',
    '192': '89YOmE1j.m2aO49bDFeHiernns7/z3i',
    '193': 'csAqRLSKYjfmjlQtJ5HcJhaLq2W7Joy',
    '194': 'qyqWyAcMqM/HunpPJIGbay8yKjDrbPy',
    '195': 'StQINg0rsJNotZBuT5cdl7ZEWL/mWl.',
    '196': '8SBVvfxtkrdIIQx5BVMrkwFbXoe/Wlu',
    '197': 'mz.rLsegoHboNApgTvUqYDWl0z2HETm',
    '198': 'qS5fSTcqp5DQGpGmGLCkyfAq.isH9wm',
    '199': 'y32cPxScFS8NsRn38tjJdYB6Pq5BkKC',
    '200': 'IdDF1cWaOagrO2BBVTVCWPdm85orL3a',
    '201': '6R8UfkOjJpZmjQZ0jfKcAOeBs.YlIL.',
    '202': 'p9r4gY60Xp1tUrqlvDxiP2ZNDm5LM5S',
    '203': 'q9ppl3ZJf6e/Hg29b.8P08O5rteoULG',
    '204': '3ru1FzccwgFsd7lfiyskv8EJ0oAoa12',
    '205': 'ejhBWxeYdNdXxugrKA8cdPiC3pfOApu',
    '206': 'c/5XSDAX0nR0sshLwv4fEWsRP4sr/bq',
    '207': '/Ls0ueZTJbK1xWg.Iiex3oxHfbs/n8e',
    '208': 'DDOKBdINEzEjN7CU.bJGVLrGanzwjZm',
    '209': '2WXjIRIyXujREbTxDetu0gqFjmnZ9AO',
    '210': 'i/DhF0749oTmjab81NAFgz8gKMvNAa2',
    '211': 'nSuDtOqmidGLV.hFCS0j4fFPFmS0nxS',
    '213': '0b/9ruErpuQ7zecKQz7Xsz0jnUky/7q',
    '214': '62z8yzJyagsWclwOLrrYoPaivtpcNyK',
    '215': 'MHESmwiMFSKX/ux1cowfzhUrx2N4TPq',
    '216': 'jcEuzAfeOLxwp381hnRsDp5tjqg07N6',
    '217': '2ZFJ5ikr82vurelX9gmjUKXZJgn.xoK',
    '218': 'Qh4jPP3kYR6QI7VwuO2LPNl4jFl9s1m',
    '219': 'H8KYXmn1a7QNRE0cXuX3YmCG3mx8Fr2',
    '220': '.9M5NziRKLrwPIHOgwgN2RdKsLq9nSS',
    '221': 'G1x.BVxu.G3AwUpQ/ezHPHPGaBFla4u',
    '222': '8.cY0ZxFwwVnf4Zhta6uRMsRKpZZoVi',
    '223': '1XN9SR7uVroz5rPY8lJRbYeRP./hzgC',
    '224': 'pAfa7HkLmwm4l9VSHLYD8D8JTSetJgq',
    '225': 'v2C8uzQVobH.Yc7FHFIjJQrpZ4a7VnO',
    '226': 'YyWhxXn2jcbe8a89BNsBx3LmF7kZagS',
    '227': 'EH0MPZYOL/eIBr2b5EtdRqRFsh0muE.',
    '228': 'DWw1Bd/cFy0Keqdij.wn2C28kDi7iZe',
    '229': 'qMxcpcQtRSf96n9XBUqJs1DulN/7AEe',
    '230': 'cRRRdIgjDjTA.S4pc5IPnOMgiWu787e',
    '231': 't6W6mEQNxzbz8AjNPCTIainQQ4LnUBu',
    '232': 'HbNCywHFWTblGd5q65Clnmu0yKGw9Wq',
    '233': 'z55iCqcepK9XWK691CSBpvvTVHAvvWm',
    '234': 'NzqfNAWsQtNH6znM0OW7k4BVYmwu2I.',
    '235': 'tCZZn6fVZFYQsBPyWjLMPspsYWwjai6',
    '236': 'mg.HFI6tYXpkmofAxg37Lby6tG0kiCa',
    '237': '3froUSCyFF4TWN2dhI/loLLrkDR/UEC',
    '239': 'W9lTR8Qm0/3mTgFZQgBWb6iBi29fls6',
    '240': 'f8mNXS5Ffw1XeR7nUhS03w5w/7wZHqa',
    '241': '/yYSe48Vcttu1ZMQDNzrdPwEi/GBVp2',
    '242': 'sxCDm.fC7CzSIjRtVmCBmUKibDZh312',
    '243': 'uQxcGFMaQyvdnUjuniNNGyezi/Fk.aK',
    '244': 'oYBe2HMhikAf.PGTYkD8UMb2UL8fDaW',
    '245': 'WEfiG/fJiNnsVWnjMQegO/LHK1GZUqS',
    '246': 'efR3YkzTYhsq5iVS/k5a738tsYK2QLi',
    '247': 'vhPRf1qq7AxPDnOs/PuD8UKahW4f4p6',
    '249': 'RKZSRU42oZvkQ/yTy9dCDmr6uL1Z54y',
    '250': 'wMo6W.nFkQmGiCa71x4nxcBND8Ms9ZO',
    '251': 'ov8HTYorFaaXinxDVSp6q1rssJ0P0Eu',
    '252': 'tMPgRKRUqm3SqtM.hkHEDCqfLJqLDQW',
    '253': 'KwWh.hq/lVwuVGgDa6GeKawEmoNS4Hy',
    '254': 'pw9PI79H75P6vQ9lwP0gH/hk3qy0KVm',
    '255': 'AHIpBuldLsiVdNqiwQSuKagmR7UK.em',
    '256': '/jB0CS3q9EJh2isyp674gyZQgTyZko6',
    '257': 'YbP8czZ71BU9gyNb8PeDTg1B6.iHbSe',
    '258': 'HRYiK4dbrz.iiXLxLQ82K3kch1nq/T2',
    '259': 'tBXV/lV1fjKeN3co48UcnOcaNFkuX1S',
    '260': 'mfwQrPLVUOqVRjQNVEZ/WRkTPIR/1da',
    '261': 'VzXlgpleiwDHHaosPVUweRiXnyc4VQC',
    '262': 'M/NVm8lGvusWB9.5pkTdG8ympF4CfgS',
    '263': 'biRDwJSsN5YAA1cJJHI.U21QpkQoZny',
    '264': 'I.4GItBRpdgTQ7fkNEdpqlly6USvqMm',
    '265': 'vkhTcPSlni7NGoGbaUfqFPjfolNBz1C',
    '266': 'UpuPJy3a1/.MTic7OE0l.dVls05h8wq',
    '267': 'bc6ynYTWDSNFJRbxQFk7E/FqmyQM1ke',
    '268': 'wfc1ipnSb/KK5toUW2b22AUZ21ERfl.',
    '269': 'HGBNe4eAQrp.ozw0D27cyl9AtXRwlkm',
    '270': 'S3B.aCcXT0oEyDHRJQNZ/Wl9ZB8PL1q',
    '271': 'BG4APUH1/eAqdj8ocAT.Xhid5PGpk7i',
    '272': 'TkXtZvIbcpIg9ltpC3C/4/aYjip9Z2C',
    '273': '.J2gRH4VqLKyelLCs4w8EZvSwqII.zC',
    '274': '5JLjNB26gpcHNJ2okdZBvR7YnQ/GHka',
    '275': 'NPg/8avz2Gyvzg0YWQmoUptPPtfvYUG',
    '276': 'xrnO1hfVUKfenLelrk6yehyWUCKvHIS',
    '277': 'VIQWkcyP2bsigF4xJYby6Nl.Lzr.s4e',
    '278': 'SfG2m.7IJScZA467AWsh3491yYgjWaC',
    '279': '6BOO1aLkwjkNgCiu4l5wCVxA2Q9NEXS',
    '280': 'PHpjzUTXul.hmtbWE.9YOYpMJK5e7zm',
    '282': 'fWW1Vh7LlXnODsBpqZlEixb1PeLVL7a',
    '283': 'uzxk2pUhe0ELHuwLdsFsGNTxOn.vO8K',
    '284': 'hkPJrua2hNhEW1Sdj2F/31b6r/09I9S',
    '285': 'bDKifiss8vqspVVEA1Vpj60C5KMru/O',
    '286': 'mBg7n2EHz1xUQ8AP7WPVYgt3suHJRhW',
    '287': 'SLi09wDhOhsPAFmYElJXqmamA0FwuSG',
    '288': 'yDU3QWQ5MJXfYPYb3g/h3iE.X00CRD.',
    '289': 'iIy0dXorOUwUhnR9T.5qg81j4njdYya',
    '290': 'hgBsWiw7sGUNapL4tYTFtVfAPMyR9/e',
    '292': 'Ln4UY27ArFyDE0eU7jvYnuSETU651ge',
    '293': 'pJXffZfVd0d84mi6Lg/QJwuo3pi5jSW',
    '294': 'OKgdnPWZbdLrv/Fn5JYZKmf6Ql7KCbO',
    '295': 'DUVYblxPG9LoXQNdFRy2o3baYf7FQlK',
    '297': 'k1kFQusZehgHhiKUT08GDaReMGC32Cq',
    '298': 'RXYrGslHNZp4EJ5D0C1eLBwOfHOfi4m',
    '299': 'QG8yh08WWS/0QXmQck30KF9hJKcyGqq',
    '301': 'kL9pQVdSSjqK98L//JYrSpIy6XRGgai',
    '302': 'nTY3h6ArFh8RJjJA8ehKCNHdE5egr8K',
    '303': 'RpGN0yMgN4rZLawPAq8iV.JGi/JhVS6',
    '304': 'rIFrZY3SMfAGjFPhNoNGqvheuODE7Xu',
    '305': 'loH3xhW51xVwqMtcf9gq0oCx70ahh.i',
    '306': '.akScJZbHH9Y.D3jiKnaiz6roKO05WW',
    '307': 'mWlsk6MaZqT6OcoqNbX3EX4cdYZD1GK',
    '309': 'k3lXtknS3z9ykYa.aBVHwux3fDVtEE6',
    '310': 'r1eP.F0Ai5JfzwXmMm4owYs9ouaMHdC',
    '311': 'JglRlH9ruGZAtMR2z7VPwIdgzh0XQbC',
    '312': 'Fza6XPf3vT1E95wPTo9BG8Q/xwTHm.a',
    '313': 'QSrq0YE/c.SshdejmLChPD20delkpZi',
    '315': 'kns7GRSg9YVyU1mUlYojxgJSpKYBSn.',
    '316': 'o3QeS8BIfra0RhJkjyJHMOkfCAmt/ue',
    '317': 's7Wrm8skGJnbkHBVOyqPE2Vv1pZxMr.',
    '319': 'LKxh71y1MKaZ8RrqAIhrdvaCgS96yAK',
    '320': 'X7v8xoL0X1EK3USAXKtGvp4MfS67f.e',
    '322': 'pwkQGpXTyNMT882sCKBaKHQsg.d1x2K',
    '323': 'hW06TzGjrh5quvEjLeKUUcZ/vZOH7V6',
    '324': 'XE7XTlF4iaxZBoeNemsXvL50VolPyz2',
    '326': 'QPfzhLkmvuJq5qecYRdnYbZnWdi03o.',
    '328': '6RgJCVKjBaBkEvkCdubFVGUzwcqjeQW',
    '329': 'hGiySbEA62fvVPRY8IVBRjnUbpu1pRW',
    '330': 'tUkXXg4HV/NbeI.Cpo3T.zgR5DFPvby',
    '331': 'eDlxYTUpDqMjH0Zoyylw56tMFIDjPI.',
    '332': 'rJ0Sq.1gA2o3kf/conqZh1QiEhYMYlq',
    '334': 'nJK8be7jln7Mgekf.fW2BwTBeaALgiG',
    '335': 'Ds2rPxyu.2.Oq/JxuE995JcHtGZmJuW',
    '336': 'gQwKhRjFo4mFmU8Xq/a2lGfnOAR54h.',
    '338': 'Lr7HVvNHtK9FQ9tKphLHsJAZRWTa7QC',
    '339': 'fogQ/0dHjpfVM1aezZa2uYOeUp72NjS',
    '340': '4oJE5tazmb4k0X6OZDw/NQROuQPbsyC',
    '341': '434YzG5bPSSFJX9p8LZhhz8QpOYiRrm',
    '342': '1xWcCmIsgxTCKqLAIWjDhsRXvfI43z6',
    '343': 'hNONXpK1VwVlAlMspAoYDZIJIA4MD2S',
    '344': '16N91WbbXga4X8hJiv39EEhdhk1ypva',
    '345': '8y6Zi6X3Ow3d7jBFr0LRLYgxhIsmbCK',
    '346': 'jGG9HLsXL/RPDg5MJmgZzwUs80fBzq2',
    '347': 'L0fYWTg20zM2WVGgV6HZzM7Cm9H2Lc.',
    '348': '4trcn1F4y3o6LoWwQkh3RQaIXDbbFYi',
    '349': 'pwY0wOwezyXmNWWjBTQL75.CCkdsebW',
    '350': 'uI2BxbsdGuddWEW4ojLAnwz6Ox9suS6',
    '351': 'CCST0OmJV7MBX3BTj0hzFRHqtN.g7gq',
    '352': 'vACeiuL5.aAceDUotoE02WX6XokweGS',
    '353': 'AYy9xVRgG0C6U8jNYs70PZTvSv7YkhC',
    '355': 'vooro2trmPXgbZPn/BkzwdpxpbrRxca',
    '357': 'bi0GZBsrPpaUv1oicN8U5NBbGHD/F/6',
    '359': 'CVDgplT.4sgo7FpwKLe8OolSvP5zkXa',
    '360': 'BP.RP6Vw7tc6rSewpvo7bkdzI/WKa9a',
    '361': '2VsGiZyh0zqTJJQOJoiNDtUq8fS/MZW',
    '363': 'IRsHspGjsCCtfRbV7gBWKL84xM0iRZ6',
    '364': 'zf9bSjxBBZ6JTM49KB0982IdQ4gyxRa',
    '366': 'NuMxFK2V3xTJIdhWAGT8NHjyym40k8O',
    '367': 'hkMfPZwA1OGXaWNXgEQTqIcMss9RD4q',
    '368': '7J5AFgne/htE.9WGuj8G10QOvxJ.qF2',
    '369': 'NXQiccfJbFIDTLy.dabdYbPOvA7Qt7O',
    '370': 'fehSIxYsY.BrpQzcSZVU6jkeDX3CPE6',
    '371': '3xMZOQdLKELgPZi3FXnJWGwyfiVI6Rq',
    '372': 'WuAfwc.UBb33oY4chbrKwPMCb2OqAYq',
    '373': 'IB.CBvDycB6BxE8KOoQEtO5neoE.3tu',
    '374': 'wyw3hb4DP6M0lA4fSiFLkag.ec6qLoC',
    '375': '9unj4FYL5gXkzclFb06q5VMcRO0nKwe',
    '377': 'pO/Jehu7ts.NdS5gMGj8ldY/je.YpH2',
    '378': 'j221izSkvYAXbhAbi2r4BkwJUIUqFMW',
    '379': 'C/7uVmGBG2pmqkELsk.Ijn5/NnH4scC',
    '380': 'cyGnw19YeQfj9CMr8r9A8.IJ6Mlynve',
    '381': 'hTfvGVSod1V1YmiBrnivAfJ/it0GSnG',
    '382': 'cdtwgkbQcjdXTxVv5fZqEK.r1wRDTWq',
    '384': 'C.zkAO49bUA.wTw8bhjuwcQSZMvEX6a',
    '385': 'P4Nc3MfpeEGK6MOhW00qH9B5zLcaOd6',
    '386': 'PvKTbvOO82V1sqOWyk2CHGW/S59LWWi',
    '387': 'fE3w4f9dxfCz1eiqbpJ2bE4fusImlgu',
    '388': 'rwnRwfzpUgnXfi9UYABBEwEO2qSEVp.',
    '390': 'iRbjQrXGucG1pRaWG2xIqeJBBD2bCUu',
    '391': '1lciwjM6jXkCAiIwu2tFcKThXyLbBhC',
    '393': 'U0CNWlqhuvOc3aKWdNmtSQBVb9hEaBy',
    '394': 'vPjHT0dwcJDwVaMnbmF1yrw8fmAktU6',
    '395': '7BZ9SwuGSRpXLrUCm513aLIpHm2z4Ta',
    '396': 't99lKbfOxAKPPzcbUKa3s4P3HFQMALG',
    '397': 'jOompOJIBx8SbF8LgJtbzvXiBzKLaT2',
    '398': 'OSZrxhaKey45M4k8tScXqK4NyxtFWxe',
    '399': 'Qv5S17.j3doNmU1M5rD0OS7L/XWjT4W',
    '400': 'sOvYqIdHgBVwsK2lbTCb26tdeL7K8ji',
    '401': 'fQaljEmp0dxC7v97DPwTzy4ta8I5nZC',
    '402': '5FXe3d/sbo.C9sqDlOxqsIkKSGoazs.',
    '405': 'jLEl/kuQcCrrH7YUN6J5TRrxzX3XiCy',
    '406': 'oABdmK1UO/XPa3XzdyjbyyF/iQZB0lS',
    '407': 'xkaXV/ZksbCumy8.pgkr6pGrsj3Jn8K',
    '408': 'sP7CYHxlJuKZgA1MtgMbiiSFe4Vh68y',
    '409': 'sVOlIVGFVAvgHAYTPiJKUVvSCGizIB.',
    '410': 'mfaCUKr5BFvbyhMd46e5nZtOU/BLL6i',
    '354': 'Ekg8Hvj7P9elgKw3u3tuGllrYXyct6q',
    '389': 'orsvOdYurL0vxykN4ywuz.hl83YRRke',
  },
}

export const checkPastParticipant = (
  slug: string,
  bibNumber: string,
  firstName: string,
  lastName: string,
) => {
  if (!(slug in pastParticipants)) return false

  const pad = Object.keys(pastParticipants[slug])[0].length
  const bib = bibNumber.padStart(pad, '0')
  if (!(bib in pastParticipants[slug])) return false

  return compareHash(
    `${firstName.trim().toUpperCase()}|${lastName.trim().toUpperCase()}`,
    `${prefix}${pastParticipants[slug][bib]}`,
  )
}
