/* tslint:disable */
/* eslint-disable */

export const s3ObjectsByObjectIdAndObjectTypeAndId = /* GraphQL */ `
  query S3ObjectsByObjectIdAndObjectTypeAndId(
    $objectId: ID!
    $objectTypeId: ModelS3ObjectByObjectIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelS3ObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    s3ObjectsByObjectIdAndObjectTypeAndId(
      objectId: $objectId
      objectTypeId: $objectTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
      }
      nextToken
    }
  }
`

export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      slug
      isComingSoon
      isOnsiteOnly
      isFree
      location
      startDateTime
      endDateTime
      registrationStartDateTime
      registrationCutOff
      categories {
        items {
          id
          name
          description
          fee
          distance
        }
        nextToken
      }
      subCategories
      entitlements {
        items {
          id
          name
          description
          eventId
        }
        nextToken
      }
      cover {
        id
        objectId
        objectType
        bucket
        key
        region
        contentType
        thumbnailKey
      }
      collectionLocation
      isVirtual
    }
  }
`
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        priority
        slug
        isComingSoon
        isOnsiteOnly
        isFree
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
        }
        collectionLocation
        isVirtual
        categories {
          items {
            id
            maxParticipants
            categoryCount {
              count
            }
          }
        }
        subCategories
      }
      nextToken
    }
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      phoneNumber
      addressLine1
      addressLine2
      adminArea2
      adminArea1
      postalCode
      countryCode
      orders {
        items {
          id
          userId
          transactionId
          status
          total
          shippingPreference
          owner
        }
        nextToken
      }
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
        }
        nextToken
      }
    }
  }
`
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      userId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        addressLine1
        addressLine2
        adminArea2
        adminArea1
        postalCode
        countryCode
        orders {
          nextToken
        }
        participants {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      transactionId
      status
      total
      participants {
        items {
          id
          bibName
          bibNumber
          firstName
          lastName
          userId
          orderId
          eventId
          categoryId
          fee
          shirtSize
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      shippingPreference
      createdAt
      updatedAt
      owner
    }
  }
`
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        transactionId
        status
        total
        participants {
          nextToken
        }
        shippingPreference
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const ordersByUserId = /* GraphQL */ `
  query OrdersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        transactionId
        status
        total
        participants {
          nextToken
        }
        shippingPreference
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getParticipant = /* GraphQL */ `
  query GetParticipant($id: ID!) {
    getParticipant(id: $id) {
      id
      bibName
      bibNumber
      teamName
      firstName
      lastName
      event {
        name
        slug
        startDateTime
        endDateTime
        isVirtual
      }
      category {
        name
        maxJerseys
        distance
      }
      fee
      shirtSize
      jerseySize
      birthdate
      gender
      phoneNumber
      address
      virtualProgress
    }
  }
`
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bibName
        firstName
        lastName
        emergencyContactName
        emergencyContactPhone
        teamName
        user {
          id
          email
          phoneNumber
        }
        order {
          id
          transactionId
          status
          total
          shippingPreference
        }
        category {
          id
          name
          description
          fee
          distance
        }
        shirtSize
        jerseySize
        birthdate
        gender
        phoneNumber
        address
        tandemName
        tandemBirthdate
        tandemShirtSize
        owner
      }
      nextToken
    }
  }
`
export const participantsByUserId = /* GraphQL */ `
  query ParticipantsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bibName
        bibNumber
        firstName
        lastName
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        orderId
        order {
          id
          userId
          transactionId
          status
          total
          shippingPreference
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          slug
          isComingSoon
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          collectionLocation
          createdAt
          updatedAt
          owner
        }
        categoryId
        category {
          id
          name
          description
          fee
          distance
          eventId
          createdAt
          updatedAt
          owner
        }
        fee
        shirtSize
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const participantsByOrderId = /* GraphQL */ `
  query ParticipantsByOrderId(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bibName
        bibNumber
        firstName
        lastName
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        orderId
        order {
          id
          userId
          transactionId
          status
          total
          shippingPreference
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          slug
          isComingSoon
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          collectionLocation
          createdAt
          updatedAt
          owner
        }
        categoryId
        category {
          id
          name
          description
          fee
          distance
          eventId
          createdAt
          updatedAt
          owner
        }
        fee
        shirtSize
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const participantsByEventId = /* GraphQL */ `
  query ParticipantsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bibName
        bibNumber
        firstName
        lastName
        userId
        user {
          id
          email
          firstName
          lastName
          phoneNumber
          addressLine1
          addressLine2
          adminArea2
          adminArea1
          postalCode
          countryCode
          createdAt
          updatedAt
          owner
        }
        orderId
        order {
          id
          userId
          transactionId
          status
          total
          shippingPreference
          createdAt
          updatedAt
          owner
        }
        eventId
        event {
          id
          name
          slug
          isComingSoon
          location
          startDateTime
          endDateTime
          registrationStartDateTime
          registrationCutOff
          collectionLocation
          createdAt
          updatedAt
          owner
        }
        categoryId
        category {
          id
          name
          description
          fee
          distance
          eventId
          createdAt
          updatedAt
          owner
        }
        fee
        shirtSize
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const participantsByCategoryId = /* GraphQL */ `
  query ParticipantsByCategoryId(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantsByCategoryId(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bibName
        firstName
        lastName
        emergencyContactName
        emergencyContactPhone
        teamName
        user {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        order {
          id
          paymentMode
          transactionId
          paymentId
          status
          total
          shippingPreference
        }
        category {
          id
          name
          description
          fee
          distance
          eventId
        }
        subCategory
        fee
        shirtSize
        jerseySize
        birthdate
        gender
        phoneNumber
        address
        email
        tandemName
        tandemBirthdate
        tandemShirtSize
        additionalId
        firstParticipantInfo
        relayEvent
        nationality
        registeredBy
        createdAt
      }
      nextToken
    }
  }
`
export const listCategoryFees = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fee
        adminFee
        adminFees
        earlyBirdFee
        earlyBirdUntil
        event {
          registrationCutOff
          firstParticipants
        }
        bonusParticipants
        teamType
      }
      nextToken
    }
  }
`
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      isIndividual
      maxParticipants
      hasJersey
      maxJerseys
      eventId
      categoryCount {
        count
      }
      bonusParticipants
      repeaterSlug
      repeaterUntil
      teamType
    }
  }
`
export const getCategoryCount = /* GraphQL */ `
  query GetCategoryCount($id: ID!) {
    getCategoryCount(id: $id) {
      id
      categoryId
      count
      jerseyCount
      createdAt
      updatedAt
      owner
    }
  }
`
export const listCategoryCounts = /* GraphQL */ `
  query ListCategoryCounts(
    $filter: ModelCategoryCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryCounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        count
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`

export const eventBySlug = /* GraphQL */ `
  query EventBySlug(
    $slug: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySlug(
      slug: $slug
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        overview
        slug
        description
        isComingSoon
        location
        locationImage {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
        }
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        categories {
          items {
            id
            name
            priority
            description
            fee
            adminFee
            adminFees
            earlyBirdFee
            earlyBirdUntil
            distance
            isIndividual
            maxParticipants
            categoryCount {
              count
            }
            isTandem
            tandemAgeRange
            isTriathlon
            bonusParticipants
            repeaterSlug
            repeaterUntil
            teamType
          }
          nextToken
        }
        subCategories
        entitlements {
          items {
            name
            priority
            description
            photo {
              key
              thumbnailKey
            }
          }
          nextToken
        }
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
        }
        fullCover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
        }
        fullCoverStyle
        raceKitCollectionLocation
        collectionLocation
        hasBibName
        hasAdditionalId
        additionalIdFieldLabel
        additionalIdName
        firstParticipants
        isVirtual
        isOnsiteOnly
        isFree
      }
      nextToken
    }
  }
`

export const formEventBySlug = /* GraphQL */ `
  query FormEventBySlug(
    $slug: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formEventBySlug(
      slug: $slug
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        overview
        description
        tshirtFees
        isComingSoon
        registrationStartDateTime
        registrationCutOff
        entitlements {
          items {
            name
            priority
            description
            photo {
              key
              thumbnailKey
            }
          }
          nextToken
        }
      }
    }
  }
`

export const formParticipantsByFormEventId = /* GraphQL */ `
  query FormParticipantsByFormEventId(
    $formEventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFormParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formParticipantsByFormEventId(
      formEventId: $formEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        participantId
        firstName
        lastName
        civilStatus
        workSite
        status
        paymentMode
        tshirtCount
        fee
        formEventId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const formParticipantsByParticipantIdAndCreatedAt = /* GraphQL */ `
  query FormParticipantsByParticipantIdAndCreatedAt(
    $participantId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formParticipantsByParticipantIdAndCreatedAt(
      participantId: $participantId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        participantId
        firstName
        lastName
        civilStatus
        workSite
        status
        paymentMode
        tshirtCount
        fee
        formEventId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`

export const eventsByIsComingSoonAndEndDateTime = /* GraphQL */ `
  query EventsByIsComingSoonAndEndDateTime(
    $isComingSoon: Int!
    $endDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByIsComingSoonAndEndDateTime(
      isComingSoon: $isComingSoon
      endDateTime: $endDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        priority
        slug
        isComingSoon
        location
        startDateTime
        endDateTime
        registrationStartDateTime
        registrationCutOff
        cover {
          id
          objectId
          objectType
          bucket
          key
          region
          contentType
          thumbnailKey
        }
        collectionLocation
        isVirtual
        categories {
          items {
            id
            maxParticipants
            categoryCount {
              count
            }
          }
        }
      }
      nextToken
    }
  }
`
