import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import dayjs from 'dayjs'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import EmptyState from '../components/EmptyState'
import GroupRegistration from '../components/GroupRegistration'
import IndividualRegistration from '../components/IndividualRegistration'
import Section from '../components/Section'
import { TEAM_TYPES } from '../constants/models'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useCategoryDetails from '../hooks/useCategoryDetails'
import useEventDetails from '../hooks/useEventDetails'

const ShoppingCartStepper = () => {
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)

  const { slug, categoryId } = useParams()
  const {
    event,
    apiLoading: loading,
    apiError,
  } = useEventDetails({
    slug,
  })

  const {
    category,
    apiLoading: catLoading,
    apiError: catApiError,
  } = useCategoryDetails({
    id: categoryId,
  })

  useEffect(() => {
    setPageLoading(loading)
  }, [loading, setPageLoading])

  const renderContent = () => {
    if (apiError || catApiError)
      return (
        <EmptyState
          header={apiError.title || catApiError.title}
          text={apiError.message || catApiError.message}>
          <Link href="/events">
            <Button variant="contained" color="secondary">
              BACK TO EVENTS
            </Button>
          </Link>
        </EmptyState>
      )

    if (!event)
      return (
        <EmptyState
          header="Hmm… We cannot find the event you are looking for"
          text=""
        />
      )

    if (event.isOnsiteOnly)
      return (
        <EmptyState
          header="Hmm… Onsite Registration only for this event"
          text=""
        />
      )

    if (
      categoryId &&
      (!category || category.categoryCount?.count >= category.maxParticipants)
    )
      return (
        <EmptyState
          header="Hmm… We cannot find the category you are looking for"
          text=""
        />
      )

    if (categoryId && category.isIndividual)
      return (
        <IndividualRegistration
          event={event}
          categoryId={categoryId}
          repeater={{
            slug: category.repeaterSlug,
            show:
              category.repeaterSlug &&
              category.repeaterUntil &&
              dayjs().isSameOrBefore(dayjs(category.repeaterUntil)),
          }}
        />
      )

    return (
      <GroupRegistration
        event={event}
        {...(categoryId &&
          Object.keys(TEAM_TYPES).includes(category.teamType) && {
            categoryId,
          })}
      />
    )
  }

  return <Section>{!loading && !catLoading && renderContent()}</Section>
}

export default ShoppingCartStepper
