/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { uniqBy } from 'lodash'

import { DEFAULT_PARTICIPANT_COUNT, TEAM_TYPES } from '../constants/models'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(isSameOrBefore)

export const isEventSoldOut = (items) => {
  const totalMaxParticipants = items.reduce(
    (total, i) => total + (i.maxParticipants || 0),
    0,
  )
  const totalCount = items.reduce(
    (total, i) => total + (i.categoryCount?.['count'] || 0),
    0,
  )
  return totalMaxParticipants && totalCount >= totalMaxParticipants
}

export const isCategorySoldOut = (count, maxParticipants) => {
  return maxParticipants && count >= maxParticipants
}

export const getBonusCategory = (cartItems, categories) => {
  let id = ''
  let count = 0

  const uniqueCatId = uniqBy(cartItems, 'categoryId')
  if (uniqueCatId.length === 1) {
    const currentCat = Object.values(categories).filter(
      (c) =>
        (c as unknown as any).id ===
        (uniqueCatId[0] as unknown as any).categoryId,
    )

    if (
      currentCat.length &&
      (currentCat[0] as unknown as any).bonusParticipants
    ) {
      id = (uniqueCatId[0] as unknown as any).categoryId
      count = (currentCat[0] as unknown as any).bonusParticipants
    }
  }

  return { id, count }
}

const getCartItemFees = (item, categories, paymentMode) => {
  const fee =
    item.categoryId in categories ? categories[item.categoryId].fee : item.fee
  const adminFee =
    item.categoryId in categories
      ? categories[item.categoryId].adminFees[paymentMode]
      : item.adminFee
  return { fee, adminFee }
}

export const generateCartItems = (cartItems, categories, paymentMode) => {
  const newCartItems = []
  const { count: bonusCount } = getBonusCategory(cartItems, categories)

  const catTriathlon =
    cartItems.filter((cartItem) => !!cartItem.isTriathlon)?.[0] || []
  const isTriathlon = Object.keys(catTriathlon).length > 0

  // teamType categories
  const catTeam =
    cartItems.filter((cartItem) =>
      Object.keys(TEAM_TYPES).includes(cartItem.teamType),
    )?.[0] || []
  const isTeam = Object.keys(catTeam).length > 0

  let total = 0

  if (isTriathlon) {
    const { fee, adminFee } = getCartItemFees(
      catTriathlon,
      categories,
      paymentMode,
    )
    total = fee + adminFee
  } else if (isTeam) {
    const { fee, adminFee } = getCartItemFees(catTeam, categories, paymentMode)
    total = fee + adminFee
  }

  cartItems.forEach((item, index) => {
    let isBonus = false
    if (cartItems.length > DEFAULT_PARTICIPANT_COUNT && bonusCount > 0)
      isBonus = index + 1 > DEFAULT_PARTICIPANT_COUNT

    // const isBonus = cartItems.length - bonusCount - 1 < index
    const { fee, adminFee } = getCartItemFees(item, categories, paymentMode)
    newCartItems.push({
      ...item,
      fee,
      adminFee,
      isBonus,
    })

    if (!isTriathlon && !isTeam && !isBonus)
      total += (fee + adminFee) * item.quantity
  })

  return { items: newCartItems, total, bonusCount }
}

export const getItemFee = (category) => {
  let adminFees = {
    CARD: category.adminFee,
    GCASH: category.adminFee,
    GRAB_PAY: category.adminFee,
    PAYMAYA: category.adminFee,
    ...(category.adminFees &&
      JSON.parse(JSON.parse(category.adminFees as string))),
  }
  if (
    category.earlyBirdUntil &&
    dayjs().isSameOrBefore(dayjs(category.earlyBirdUntil))
  ) {
    if ('EARLY_BIRD' in adminFees) {
      adminFees = adminFees.EARLY_BIRD
    }
  }

  const fee =
    category.earlyBirdUntil &&
    category.earlyBirdFee &&
    dayjs().isSameOrBefore(dayjs(category.earlyBirdUntil))
      ? category.earlyBirdFee
      : category.fee

  return { adminFees, fee }
}

export const getUserOnsiteEvents = (attributes) => {
  if (!attributes) return []
  if (!('custom:onsite_events' in attributes)) return []

  const events = attributes['custom:onsite_events'].split(',')
  return events
}
